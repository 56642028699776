/*
    Adds Boostrap 4 styled form validation feedback
    https://getbootstrap.com/docs/4.3/components/forms/#custom-styles
*/
export default function setupFormSubmission() {
    /*
        Adds Boostrap 4 styled form validation feedback
        Base on: https://getbootstrap.com/docs/4.3/components/forms/#custom-styles
    */
    document.addDynamicEventListener('.js-form-control', 'click', (e, form) => {
        if (form.classList.contains('js-validation')) {
            form.classList.remove('was-validated');
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }
            form.classList.add('was-validated');
        }
        if (form.classList.contains('js-prevent-submission')) {
            e.preventDefault();
            e.stopPropagation();
        }
    });

    /*
        Some forms are not normal form submits we disable them above
        However when a user hits enter on an input we still want to submit
        the form.  Apply `js-enter-submit` to the input and `js-form-submit` to
        the button to be clicked to submit the form
    */
    document.addDynamicEventListener('.js-enter-submit', 'keypress', (e, input) => {
        const enterKey = e.keyCode === 13;
        if (enterKey) {
            e.preventDefault();
            input.closest('form').querySelector('.js-form-submit').click();
        }
    });

    /*
        Sometimes [type=reset] is not possible or does not work reliably
        Adding a class `js-reset-form` will reset the form to what it was on page load
    */
    document.addDynamicEventListener('.js-reset-form', 'click', (e, button) => {
        const form = button.closest('form');
        const sliders = form.querySelectorAll('.js-range');

        form.reset();

        if (sliders) {
            sliders.forEach((slider) => {
                slider.noUiSlider.reset();
            });
        }
    });
}
