/*
    add event listener to toggle is-show-first-three class
    when js_list-toggle link is clicked
*/
export default function setupListToggle() {
    document.addDynamicEventListener('.jsclass_list-toggle', 'click', (e, button) => {
        const ul = button.previousElementSibling;
        if (ul.classList.contains('is-show-first-three')) {
            ul.classList.remove('is-show-first-three');
            // eslint-disable-next-line no-param-reassign
            button.innerHTML = 'Show Less';
        } else {
            ul.classList.add('is-show-first-three');
            // eslint-disable-next-line no-param-reassign
            button.innerHTML = 'Show More';
        }
        e.preventDefault();
    }, false);
}
