/*
    Handles toggling classes for `js-checkbox-button`
    Checked = `btn-outline-primary`
    Unchecked = `btn-primary`
*/
export default function setupButton() {
    document.addDynamicEventListener('.js-checkbox-button', 'click', (e, button) => {
        button.parentNode.classList.toggle('btn-outline-primary');
        button.parentNode.classList.toggle('btn-primary');
    });
}
