// Explicit import to avoid ES6 Uglify Errors
import Readmore from 'readmore-js/dist/readmore';
/*
    Handles shrinking long copy content and replacing with read more/less toggle
*/
export function setupReadMore() {
    // eslint-disable-next-line no-new
    new Readmore('.js-read-more', {
        speed: 200,
        collapsedHeight: '50px',
        moreLink: `<div class='position-absolute w-auto bg-white read-more-toggle'>
                        <span class='d-inline-block'>...</span>
                        <a href='#'>Read more</a>
                    </div>`,
        lessLink: '<a href="#">Read less</a>',
    });
}

export function setupReadMoreSP() {
    // eslint-disable-next-line no-new
    new Readmore('.js-read-more-sp', {
        speed: 200,
        collapsedHeight: '120px', // 16px font-size * 1.5 line-height * 5 lines
        moreLink: '<a class="d-block font-size-sm my-2 mb-3" href="#">Read more</a>',
        lessLink: '<a class="d-block font-size-sm mb-3" href="#">Read less</a>',
    });
}
