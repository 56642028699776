/*
    Sets up hiding/showing of the sidebar and the page overlay
    Clicking the overlay will hide the sidebar
*/
export default function setupSideBar() {
    document.addDynamicEventListener('.js-sidebar-toggle', 'click', () => {
        document.querySelector('.js-navbar-side').classList.toggle('navbar-side__reveal');
        document.querySelector('.js-overlay').classList.toggle('d-block');
    });

    document.addDynamicEventListener('.js-overlay', 'click', () => {
        document.querySelector('.js-sidebar-toggle').click();
    });

    // FIXME: Super Hacky, investigate why default behavior doesn't work
    document.addDynamicEventListener('.list-group-item-action', 'click', (e, label) => {
        setTimeout(() => {
            const checkbox = label.querySelector('[type="checkbox"]');
            if (checkbox) {
                e.stopPropagation();
                e.preventDefault();
                checkbox.checked = !checkbox.checked;
            }
        }, 1);
    });

    // If the sidebar is open and escape key is clicked close it
    window.onkeydown = function escapeSidebar() {
        const sideBar = document.querySelector('.js-navbar-side');
        if (sideBar && sideBar.classList.contains('navbar-side__reveal')) {
            document.querySelector('.js-sidebar-toggle').click();
        }
    };
}
