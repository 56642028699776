/**
 * Setup range / rangeData Components
 * https://refreshless.com/noUiSlider/more/
*/
import noUiSlider from 'nouislider';

export function toolTipFormatter(range) {
    function innerFormatter(value) {
        const cleanValue = parseFloat(value).toFixed(2).replace(/\.0+$/, '');
        // eslint-disable-next-line max-len
        return `${range.getAttribute('data-tooltip-prefix')}${cleanValue}${range.getAttribute('data-tooltip-suffix')}`;
    }
    return {
        to: innerFormatter,
        from: innerFormatter,
    };
}

export function setupRange() {
    // Not selector used to prevent double setup after ajax
    const ranges = document.querySelectorAll('.js-range:not(.js-range-done)');

    if (ranges) {
        ranges.forEach((range) => {
            range.classList.add('js-range-done');
            const holder = range.closest('.js-range-holder');
            const step = parseFloat(range.getAttribute('data-step') || 1);
            const start = parseFloat(range.getAttribute('data-start') || 1);
            const stop = parseFloat(range.getAttribute('data-stop') || 100);
            const min = parseFloat(range.getAttribute('data-min') || 1);
            const max = parseFloat(range.getAttribute('data-max') || 100);
            let rangeData = range.getAttribute('data-rangeData') || false;
            let snap = false;

            if (!rangeData) {
                rangeData = { min, max };
            } else {
                const rangeDatadArray = rangeData.trim().split(',').sort((a, b) => a - b);
                const percentStep = parseInt(100 / rangeDatadArray.length, 10);
                rangeData = {};
                snap = true;

                rangeDatadArray.forEach((value, index) => {
                    const cleanValue = parseFloat(value);
                    if (index === 0) {
                        rangeData.min = cleanValue;
                    } else if (index === rangeDatadArray.length - 1) {
                        rangeData.max = cleanValue;
                    } else {
                        rangeData[`${percentStep * index + 1}%`] = cleanValue;
                    }
                });
            }

            noUiSlider.create(range, {
                connect: true,
                start: [start, stop],
                step,
                range: rangeData,
                snap,
                tooltips: [toolTipFormatter(range), toolTipFormatter(range)],
            });
            if (holder) {
                const startInput = holder.querySelector('.js-range-start');
                const stopInput = holder.querySelector('.js-range-stop');

                if (startInput && stopInput) {
                    range.noUiSlider.on('set', (values) => {
                        const startValue = parseFloat(values[0]);
                        const stopValue = parseFloat(values[1]);

                        startInput.value = '';
                        stopInput.value = '';

                        if (startValue !== min) {
                            startInput.value = startValue;
                        }
                        if (stopValue !== max) {
                            stopInput.value = stopValue;
                        }
                    });
                }
            }
        });
    }
}

export function resetRange(rangeObj) {
    const holder = rangeObj.closest('.js-range-holder');
    const start = rangeObj.noUiSlider.options.range.min;
    const stop = rangeObj.noUiSlider.options.range.max;
    holder.querySelector('.js-range-start').value = start;
    holder.querySelector('.js-range-stop').value = stop;

    rangeObj.noUiSlider.updateOptions({
        start: [start, stop],
    });
}

export default function setupSliders() {
    setupRange();
}
