/* globals jQuery */
/*
    Adds Popover Support
    https://getbootstrap.com/docs/4.3/components/popovers/
*/
export default function setupPopover() {
    // Not selector used to prevent double setup after ajax
    jQuery('.js-popover:not(.js-override):not(.js-popover-done)').addClass('js-popover-done').popover({
        trigger: 'focus',
        html: true,
    });
    jQuery('.js-tooltip:not(.js-override):not(.js-tooltip-done)').addClass('js-tooltip-done').popover({
        trigger: 'hover',
        html: true,
    });
}
