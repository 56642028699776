/* global jQuery */
/*
    Select 2 Global Configuration
    Sets up select2 widgets on page load.  By default all `select` elements will be
    initilized in select2.  Unique overrides are defined as well, they should include
    the `js-override` as well as their unique class
    https://select2.org/configuration/options-api
*/

// Extend default select2 configuration
// https://stackoverflow.com/questions/35500508/how-to-disable-the-title-in-select2/46327202#46327202
(function extendSelect2($) {
    jQuery.fn.select2.defaults.set('theme', 'bootstrap4');
    const Defaults = $.fn.select2.amd.require('select2/defaults');

    $.extend(Defaults.defaults, {
        selectionTitleAttribute: true,
    });

    // SingleSelection
    //
    const SingleSelection = $.fn.select2.amd.require('select2/selection/single');

    const updateSingleSelection = SingleSelection.prototype.update;

    SingleSelection.prototype.update = function update() {
        // invoke parent method
        // eslint-disable-next-line prefer-rest-params
        updateSingleSelection.apply(this, Array.prototype.slice.apply(arguments));

        const selectionTitleAttribute = this.options.get('selectionTitleAttribute');

        if (selectionTitleAttribute === false) {
            const $rendered = this.$selection.find('.select2-selection__rendered');
            $rendered.removeAttr('title');
        }
    };

    // MultipleSelection

    const MultipleSelection = $.fn.select2.amd.require('select2/selection/multiple');

    const updateMultipleSelection = MultipleSelection.prototype.update;

    MultipleSelection.prototype.update = function update() {
        // invoke parent method
        // eslint-disable-next-line prefer-rest-params
        updateMultipleSelection.apply(this, Array.prototype.slice.apply(arguments));

        const selectionTitleAttribute = this.options.get('selectionTitleAttribute');

        if (selectionTitleAttribute === false) {
            const $rendered = this.$selection.find('.select2-selection__rendered');
            $rendered.find('.select2-selection__choice').removeAttr('title');
        }
    };
}(window.jQuery));

export default function setupSelectList() {
    // Not selector used to prevent double setup after ajax
    jQuery('select:not(.js-override):not(.hs-input):not(.js-select-done)').addClass('js-select-done').select2({
        minimumResultsForSearch: 5,
    });
}
