/**
 * addEventListener Helper
 * Attaches event to `this` then on `event` if `selector` matches `callback` is fired
 * TODO: Extend prototype for any DOM Object not just `document`
 * @param {this} this `document`
 * @param {string} selector https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector
 * @param {event} event https://developer.mozilla.org/en-US/docs/Web/Events
 * FIXME: `removeEventListener` only works when this is a named function
 * @param {function} callback Any named or anonymous function
 * @param {object} options https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Parameters
 */
if (!HTMLDocument.prototype.addDynamicEventListener) {
    // eslint-disable-next-line max-len
    HTMLDocument.prototype.addDynamicEventListener = function addDynamicEventListener(selector, event, callback, options) {
        function getConditionalCallback() {
            return function returnCallback(e) {
                let { target } = e;
                if (!target) return;
                /*  Checks if target matches the current element or any of its direct parents
                    https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
                */
                if (!target.matches(selector)) {
                    if (!e.target.closest(selector)) {
                        return;
                    }
                    target = e.target.closest(selector);
                }
                callback.call(this, e, target);
            };
        }

        this.addEventListener(event, getConditionalCallback(), options);
    };
}
