import setupButton from './button';
import setupForm from './form';
import setupPopover from './popover';
import setupListToggle from './listtoggle';
import { setupReadMore, setupReadMoreSP } from './readmore';
import setupSelectList from './selectlist';
import setupSideBar from './sidebar';
import setupSliders from './slider';

import '@/utils/extend';

// eslint-disable-next-line func-names
(function () {
    // Used in our sass and css to apply style to browser specific
    document.documentElement.setAttribute('data-browser', navigator.userAgent);

    document.addEventListener('DOMContentLoaded', () => {
        setupButton();
        setupForm();
        setupPopover();
        setupListToggle();
        setupReadMore();
        setupReadMoreSP();
        setupSelectList();
        setupSliders();
        setupSideBar();
    }, false);
}());
